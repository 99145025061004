<template>
  <PvMessage
    v-if="loggedIn && !$route.path.includes('connection_config') && (itsmSystem === 'None' || missingFields.length > 0)"
    class="mt-0"
    :closable="false"
    severity="warn"
  >
    No ITSM connection configured. TicketAnalyzer will not work without connecting to an ITSM.
    <RouterLink
      :to="{ name: 'connection_config' }"
    >
      Go to Connection Config
    </RouterLink>
  </PvMessage>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ItsmWarning',
  props: {
    loggedIn: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({ itsmSystem: 'itsmSystem', missingFields: 'missingFields' }),
  },
};
</script>
