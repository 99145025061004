<template>
  <AppTemplate
    v-bind="{
      pages,
      showSidebar,
      toolDetails: {
        name: 'TicketAnalyzer',
        logo: require('./assets/supwiz-logo-flat.svg'),
        env,
      },
      showSpinner: pageLoading,
      loggedIn,
    }"
    notifications-center
    sidebar-search
  >
    <template #header>
      <ButtonIcon
        v-if="loggedIn"
        id="info-button"
        v-tooltip="'Show software version'"
        aria-label="Show software version"
        class="size-9"
        :aria-controls="versionInfoModalShowing ? 'version-info-modal' : null"
        :aria-expanded="versionInfoModalShowing"
        icon="info"
        @click="versionInfoModalShowing = true"
      />
      <PvDialog
        id="version-info-modal"
        v-model:visible="versionInfoModalShowing"
        header="Version Information"
        modal
      >
        <PvDataTable
          size="small"
          :value="versionInfo"
        >
          <PvColumn field="name" header="Name" />
          <PvColumn field="value" header="Value" />
        </PvDataTable>
        <template #footer>
          <PvButton
            autofocus
            label="Close"
            severity="secondary"
            @click="versionInfoModalShowing = false"
          />
        </template>
      </PvDialog>
    </template>
    <template #page-content>
      <ItsmWarning
        v-bind="{ loggedIn }"
      />
      <RouterView v-slot="{ Component, route }">
        <transition name="fade" mode="out-in">
          <component :is="Component" :key="route.path" />
        </transition>
      </RouterView>
    </template>
  </AppTemplate>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import AppTemplate from 'supwiz/components-vue3/template/AppTemplate.vue';
import ButtonIcon from 'supwiz/components-vue3/template/shared/components/ButtonIcon.vue';
import ItsmWarning from '@/components/ItsmWarning.vue';
import { versionInfoArray } from '@/js/utils';

export default {
  components: { AppTemplate, ItsmWarning, ButtonIcon },
  data() {
    return {
      versionInfoModalShowing: false,
    };
  },
  computed: {
    ...mapState('sidebar', ['pageLoading', 'showSidebar']),
    ...mapGetters('sidebar', ['loggedIn', 'pages']),
    ...mapState('auth', ['topMessage']),
    env() {
      if (['test', 'dev'].includes(window.taEnv.toLowerCase())) return window.taEnv;
      return '';
    },
    versionInfo() { return versionInfoArray(); },
  },
  watch: {
    topMessage() {
      if (this.topMessage) {
        this.showWarning({
          title: 'Something happened',
          text: this.topMessage,
          severity: 'error',
        });
      }
    },
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
  },
};
</script>

<style>
#app {
  background-image:
    linear-gradient(to right, rgba(2,76,115,0.97), rgba(2,76,115,0.97)),
    url('./assets/background-pattern.svg');
}
</style>
