import { createSsoEndpoints } from 'supwiz/util/sso';

// If using locally
let backendLocation = window.backendLocation;
if (backendLocation === '$BACKEND_LOCATION') {
  backendLocation = undefined;
}
export const BACKEND_HOST = backendLocation || 'https://ticketanalyzer.local';
export const BACKEND_API_PATH = `${BACKEND_HOST}/api`;

export const endpoints = {
  // SSO
  ...createSsoEndpoints(BACKEND_API_PATH),

  // Docs
  swaggerFile: `${BACKEND_HOST}/docs/openapi/`,

  // Mount info
  mountInfo: `${BACKEND_API_PATH}/data/mount-info/`,
  tagOptions: `${BACKEND_API_PATH}/extdata/tag-options/`,
  connectionConfig: `${BACKEND_API_PATH}/extdata/connection-config/`,
  itsmSystem: `${BACKEND_API_PATH}/extdata/itsm-system/`,

  // Auth and access permissions
  obtainJWT: `${BACKEND_HOST}/auth/token/`,
  refreshJWT: `${BACKEND_HOST}/auth/token/refresh/`,
  verifyJWT: `${BACKEND_HOST}/auth/token/verify/`,
  logoutJWT: `${BACKEND_HOST}/auth/token/logout/`,
  getPermissions: `${BACKEND_HOST}/accounts/permission/user/`,

  // Entity (data, options, etc.)
  tableOptions: `${BACKEND_API_PATH}/data/table/options/`,

  // Meta (data collections, field configs, etc.)
  dataCollection: `${BACKEND_API_PATH}/data/meta/data-collection/`,

  // Feedback
  myFeedbackQueue: `${BACKEND_API_PATH}/feedback/user/queue/`,
  myFeedback: `${BACKEND_API_PATH}/feedback/user/feedback/`,
  prettyConfig: `${BACKEND_API_PATH}/feedback/display-config/`,

  // Admin feedback
  feedbackAdminQueueOptions: `${BACKEND_API_PATH}/feedback/queue-options/`,
  feedbackAdminQueue: `${BACKEND_API_PATH}/feedback/admin/queue/`,
  feedbackAdmin: `${BACKEND_API_PATH}/feedback/admin/feedback/`,

  // MoMa
  trainedModel: `${BACKEND_API_PATH}/moma/trained-model/`,
  simpleTrainedModel: `${BACKEND_API_PATH}/moma/simple-trained-model/`,
  task: `${BACKEND_API_PATH}/moma/task/`,
  finishedTask: `${BACKEND_API_PATH}/moma/finished-task/`,
  module: `${BACKEND_API_PATH}/moma/module/`,
  stageHistory: `${BACKEND_API_PATH}/moma/staging/history/`,
  stage: `${BACKEND_API_PATH}/moma/staging/stage/`,
  periodicTask: `${BACKEND_API_PATH}/moma/periodic-task/`,
  userTaskStatus: `${BACKEND_API_PATH}/moma/task-list/`,
  workSpace: `${BACKEND_API_PATH}/data/meta/work-space/`,
  inputField: `${BACKEND_API_PATH}/moma/input-field/`,
  preprocessingConfig: `${BACKEND_API_PATH}/moma/preprocessing-config/`,
  pretrainedModels: `${BACKEND_API_PATH}/moma/pretrained-models/`,

  // Custom Rules
  ruleCollectionGroup: `${BACKEND_API_PATH}/custom-rules/rule-collection-group/`,
  ruleCollection: `${BACKEND_API_PATH}/custom-rules/rule-collection/`,
  rule: `${BACKEND_API_PATH}/custom-rules/rule/`,
  suggestedRule: `${BACKEND_API_PATH}/custom-rules/suggest-rule/`,
  stagedRule: `${BACKEND_API_PATH}/custom-rules/staged-rule/`,
  ruleStage: `${BACKEND_API_PATH}/custom-rules/staging/stage/`,
  ruleStageHistory: `${BACKEND_API_PATH}/custom-rules/staging/history/`,
  testTextCondition: `${BACKEND_API_PATH}/custom-rules/test/text-condition/`,
  testRules: `${BACKEND_API_PATH}/custom-rules/test/rules/`,
  testCase: `${BACKEND_API_PATH}/custom-rules/test-case/`,

  // Stats
  stats: `${BACKEND_API_PATH}/stats/serve/`,

  // Swaps
  swapsAI: `${BACKEND_API_PATH}/swaps/ai/`,
  swapUnit: `${BACKEND_API_PATH}/swaps/swap-unit/`,
  testSiteMeta: `${BACKEND_API_PATH}/swaps/test-site/meta/`,
  predictorCall: `${BACKEND_API_PATH}/swaps/predictor-call/`,

  // Data Sync and Purge
  syncConfig: `${BACKEND_API_PATH}/data/sync/config/`,
  syncOneOff: `${BACKEND_API_PATH}/data/sync/one-off/`,
  purgeConfig: `${BACKEND_API_PATH}/data/purge/config/`,
  purgeOneOff: `${BACKEND_API_PATH}/data/purge/one-off/`,

  // Anomaly Detection
  findAnomaliesConfig: `${BACKEND_API_PATH}/anomaly/find/config/`,
  findAnomaliesOneOff: `${BACKEND_API_PATH}/anomaly/find/one-off/`,
  anomalyObject: `${BACKEND_API_PATH}/anomaly/object/`,

  // Similarity
  similarAnalysis: `${BACKEND_API_PATH}/analyzer/similar/analyze/`,
  ticketSearch: `${BACKEND_API_PATH}/extdata/search/`,
  ticketExport: `${BACKEND_API_PATH}/extdata/export-search/`,

  // ExtData
  ticketIdLookup: `${BACKEND_API_PATH}/extdata/ticket-ids/`,
  savedQuery: `${BACKEND_API_PATH}/extdata/saved-query/`,
  oneOffHealthScore: `${BACKEND_API_PATH}/extdata/health-update/one-off/`,
  taskHandling: `${BACKEND_API_PATH}/extdata/task/`,
  taskQueueStatus: `${BACKEND_API_PATH}/extdata/task/queue-status/`,

  // Access and User Management
  users: `${BACKEND_API_PATH}/access/user-info/`,
  usersManagement: `${BACKEND_API_PATH}/access/user/`,
  itsmUsers: `${BACKEND_API_PATH}/access/itsm-user/`,
  testUserPassword: `${BACKEND_API_PATH}/access/validate-password/`,

  // Anonymization
  anonAnonymizers: `${BACKEND_API_PATH}/data/anon/anonymizer/`,
  anonAnonymizedFields: `${BACKEND_API_PATH}/data/anon/anonymized-field/`,
  anonRegexTest: `${BACKEND_API_PATH}/data/anon/test-regex/`,
  anonFieldTest: `${BACKEND_API_PATH}/data/anon/test-field/`,

  // Routing
  queueGroup: `${BACKEND_API_PATH}/routing/queue-group/`,
  queue: `${BACKEND_API_PATH}/routing/queue/`,
  queueStage: `${BACKEND_API_PATH}/routing/staging/stage/`,
  queueStageHistory: `${BACKEND_API_PATH}/routing/staging/history/`,
  routingSession: `${BACKEND_API_PATH}/routing/logs/session/`,
  sessionTag: `${BACKEND_API_PATH}/routing/logs/session-tag/`,
  explainTicket: `${BACKEND_API_PATH}/routing/explain`,

};
